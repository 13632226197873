<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { debounce } from 'vue-debounce'
import PaginationCustom from '@/components/table/Pagination.vue'
// import appData from '@/data'

export default {
   name: 'ScrapSource',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
   },
   data() {
      return {
         nib: '',
         loading: true,
         loadingRun: false,
         file: null,
         serverParams: {
            columnFilters: {},
            sort: {
               field: '',
               type: '',
            },
            search: '',
            page: 1,
            perPage: 10,
         },
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'NIB / Nama',
               field: 'nib',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Skala / Email / Username',
               field: 'username',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Terbit',
               field: 'tgl',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Status',
               field: 'status',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Error',
               field: 'error',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],

      }
   },
   computed: {
      ...mapState({
         myData: state => state.OssScrap.items,
         myCount: state => state.OssScrap.countAll,
      }),
      statusNIBVariant() {
         return status => (status ? 'primary' : 'danger')
      },
      isLoading() {
         return this.$store.state.isLoading
      },
   },
   watch: {
      loadingRun(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'OssScrap/getDataTable',
         runScrap: 'OssScrap/runScrap',
         runLogin: 'OssScrap/runLogin',
         storeNib: 'OssScrap/storeNib',
         importData: 'OssScrap/importData',
         clearError: 'OssScrap/clearError',
         cetakIzin: 'OssIzinRba/cetakIzin',
         DownloadNib: 'OssScrapDownloadNib/runScrap',

      }),
      onRunScrap(nib) {
         this.loadingRun = true
         this.runScrap(nib).then(() => {
            this.clearError(nib).then(() => {
               this.loadingRun = false
               this.loadItems()
            }, () => {
               this.loadingRun = false
            })
         }, () => {
            this.loadItems()
            this.loadingRun = false
         })
      },
      onAddItem() {
         this.$bvModal.show('modal-form')
      },
      onImport() {
         this.$bvModal.show('modal-import')
      },
      onLogin() {
         this.loadingRun = true
         this.runLogin().then(() => {
            this.loadingRun = false
         }, () => {
            this.loadingRun = false
         })
      },
      onFilter() {

      },
      onSaveNib() {
         this.storeNib(this.nib.trim()).then(() => {
            this.nib = ''
            this.loadItems()
         }, () => {

         })
      },
      onSave() {
         if (this.file) {
            this.importData(this.file).then(() => {
               this.file = null
               this.loadItems()
               this.$bvModal.hide('modal-import')
            })
         } else {
            this.$bvToast.toast('File Excel belum dipilih', {
               title: 'Error', variant: 'danger', solid: true,
            })
         }
      },
      onDownloadNib(idPermohonan, nib) {
         this.loadingRun = true
         const data = {
            id_permohonan: idPermohonan,
            nib,
         }

         this.DownloadNib(data).then(() => {
            this.loadItems()
            this.loadingRun = false
         }, () => {
            this.loadingRun = false
         })
      },
      // VUE GOOD TABLE
      async loadItems() {
         this.loading = true
         await this.getData(this.serverParams)
      },
      updateParams(newProps) {
         // eslint-disable-next-line prefer-object-spread
         this.serverParams = Object.assign({}, this.serverParams, newProps)
      },
      onPageChangePagination(page) {
         this.updateParams({ page })
         this.loadItems()
      },
      onPerPageChangePagination(perPage) {
         this.updateParams({ perPage, page: 1 })
         this.loadItems()
      },
      onColumnFilter(params) {
         this.updateParams(params)
         this.loadItems()
      },
      onSearch: debounce(function search(params) {
         this.serverParams.search = params
         this.serverParams.page = 1
         this.loadItems()
      }, 500),
      // onRowClick(params) {
      //    this.$router.push(`/oss/nib/${params.row.id_permohonan}`)
      // },
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data Scrap Source">
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="6">
               <b-form @submit.stop.prevent>
                  <div class="d-flex align-items-center justify-content-end">
                     <b-form-input
                        v-model="searchTerm"
                        class="d-inline-block mb-1"
                        placeholder="Search..."
                        autocomplete="off"
                        type="text"
                        aria-label="Search"
                        @input="onSearch"
                     />
                  </div>
               </b-form>
            </b-col>
            <b-col cols="12" md="6">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1">
                     <b-button variant="outline-secondary" size="sm" @click="onAddItem()">
                        <feather-icon icon="PlusIcon" />
                     </b-button>
                     <b-button variant="outline-secondary" size="sm" @click="onImport()">
                        <feather-icon icon="UploadIcon" />
                     </b-button>
                     <b-button variant="outline-secondary" size="sm" @click="onFilter()">
                        <feather-icon icon="FilterIcon" />
                     </b-button>
                     <b-button variant="outline-secondary" size="sm" @click="onLogin()">
                        <feather-icon icon="LogInIcon" />
                     </b-button>
                     <b-button variant="outline-secondary" size="sm" @click="loadItems()">
                        <feather-icon icon="RefreshCwIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="onAddItem()">
                           Tambah NIB
                        </b-dropdown-item>
                        <b-dropdown-item @click="onFilter()">
                           Filter
                        </b-dropdown-item>
                        <b-dropdown-item @click="loadItems()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
         </b-row>

         <!-- search input -->

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            mode="remote"
            :columns="columns"
            :rows="myData"
            :total-rows="myCount"
            :is-loading.sync="loading"
            :line-numbers="true"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
            :search-options="{
               enabled: true,
               externalQuery: searchTerm,
            }"
            @on-column-filter="onColumnFilter"
            @on-search="onSearch"
         >
            <template slot="table-row" slot-scope="props">
               <!-- Column: Name -->
               <span v-if="props.column.field == 'tgl'">
                  <center>{{ props.row.tanggal_terbit_oss | moment("DD-MM-YYYY") }}</center>
               </span>

               <span v-if="props.column.field == 'nib'">
                  <b>{{ (props.row.nama_perusahaan) }}</b>
                  <br>
                  <hr>
                  <b class="text-primary">{{ (props.row.nib) }}</b>
                  <hr>
                  <small>{{ props.row.uraian_jenis_nib }}</small>
                  <hr>
                  <small>{{ props.row.jenis_perusahaan_uraian }}</small>
               </span>

               <span v-if="props.column.field == 'username'">
                  <b class="text-primary">{{ (props.row.skala_usaha) }}</b>
                  <hr>
                  <small>
                     <b>{{ (props.row.username) }}</b>
                  </small>
                  <hr>
                  <small>{{ (props.row.email) }}</small>
               </span>
               <span v-if="props.column.field == 'status'">
                  <center>
                     <b-badge variant="dark">
                        <small>{{ (props.row.status_nib) }}</small>
                     </b-badge>
                  </center>
               </span>

               <span v-if="props.column.field == 'error'">
                  <center>
                     <b-badge v-if="props.row.message" variant="danger">
                        <small>Error</small>
                     </b-badge>
                     <hr>
                     <small>{{ (props.row.message) }}</small>
                  </center>
               </span>

               <span v-if="props.column.field == 'action'">
                  <b-button size="sm" block variant="outline-dark" @click="onRunScrap(props.row.nib)">
                     <feather-icon icon="PlayIcon" class="mr-50" />
                     <small>Run Scrap</small>
                  </b-button>
                  <b-button
                     size="sm"
                     block
                     :variant="statusNIBVariant(props.row.s3_path)"
                     @click="onDownloadNib(props.row.permohonan_id, props.row.nib)"
                  >
                     <feather-icon icon="DownloadCloudIcon" class="mr-50" />
                     <small>Download NIB</small>
                  </b-button>
               </span>

               <!-- Column: Common -->
               <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
               <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
                  @update:page="onPageChangePagination"
                  @update:perpage="onPerPageChangePagination"
               />
            </template>
         </vue-good-table>
      </b-card-code>

      <b-modal
         id="modal-form"
         cancel-variant="outline-secondary"
         ok-title="Simpan"
         cancel-title="Close"
         centered
         title="Form"
         @ok="onSaveNib"
      >
         <b-form>
            <b-form-group>
               <label for="nib">NIB:</label>
               <b-form-input id="nib" v-model="nib" type="text" placeholder />
            </b-form-group>
         </b-form>
      </b-modal>

      <b-modal
         id="modal-import"
         scrollable
         size="lg"
         centered
         hide-footer
         title="Form Import"
         no-close-on-backdrop
      >
         <b-form-group label="File Excel">
            <b-form-file
               v-model="file"
               placeholder="Choose a file or drop it here..."
               drop-placeholder="Drop file here..."
            />
         </b-form-group>
         <hr>
         <b-button
            v-b-tooltip.hover.top="'Simpan'"
            variant="primary"
            type="button"
            class="float-right"
            :disabled="isLoading"
            @click.prevent="onSave"
         >
            <b-spinner v-if="isLoading" small />Import
         </b-button>
      </b-modal>
   </div>
</template>

<style scoped>
.borderless>tr>td {
   border: none;
   padding: 0em;
}

table>tbody>tr>td>span>hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}

.nowrap {
   white-space: nowrap;
}
</style>
